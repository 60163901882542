import { default as overviewAkuahBuksYMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/_/banner/overview.vue?macro=true";
import { default as index73MLNudL3hMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/[...slug]/index.vue?macro=true";
import { default as thank_45youOo6f4Bd5jOMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/[...slug]/thank-you.vue?macro=true";
import { default as index0qf58pPnKvMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/404/index.vue?macro=true";
import { default as indexcORn4zXKxKMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/account/changePassword/index.vue?macro=true";
import { default as successsbK0tyUvvIMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/account/changePassword/success.vue?macro=true";
import { default as indexZbSjlZVK4rMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/account/index.vue?macro=true";
import { default as indexzP4KpywIfiMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/account/orders/index.vue?macro=true";
import { default as indexhBLdxZF0PpMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/account/profile/index.vue?macro=true";
import { default as indexitBeZ4moR9Meta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/account/resetPassword/index.vue?macro=true";
import { default as successu13ZT6LkdQMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/account/resetPassword/success.vue?macro=true";
import { default as itemsE1xGricw7sMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/account/returns/checkout/items.vue?macro=true";
import { default as reviewiTrUQdQ96gMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/account/returns/checkout/review.vue?macro=true";
import { default as shippingo53mbC5o5iMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/account/returns/checkout/shipping.vue?macro=true";
import { default as formse9ltLOKxiMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/account/returns/complaint/form.vue?macro=true";
import { default as indexTllS93FZetMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/account/returns/index.vue?macro=true";
import { default as address1WGt9DT7W8Meta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/checkout/address.vue?macro=true";
import { default as basketsWNsuKFVhXMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/checkout/basket.vue?macro=true";
import { default as loginRm6TfayuOMMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/checkout/login.vue?macro=true";
import { default as payment_45shippingMtj78GueNiMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/checkout/payment-shipping.vue?macro=true";
import { default as reviewcwdgDP8fqiMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/checkout/review.vue?macro=true";
import { default as theme_45testnNuKo5hP21Meta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/debug/theme-test.vue?macro=true";
import { default as tool1UO2P42slKMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/debug/tool.vue?macro=true";
import { default as indexjeOgi9qUiQMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/login/index.vue?macro=true";
import { default as successUkT9VpSGU0Meta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/login/success.vue?macro=true";
import { default as _91slug_93Oafyttdu0JMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/preview/banner/[slug].vue?macro=true";
import { default as indexqFWqcFZlHMMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/productlists/auto-delivery/index.vue?macro=true";
import { default as _91id_93Mr2zbZZ430Meta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/productlists/favorites/[...slug]/[id].vue?macro=true";
import { default as indexYvN5DvXmeHMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/productlists/favorites/index.vue?macro=true";
import { default as indexwvvPpENwF9Meta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/productlists/index.vue?macro=true";
import { default as indexhgcdUk12FwMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/productlists/order-alarm/index.vue?macro=true";
import { default as index9DwGcWA5XBMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/productlists/ordered-products/index.vue?macro=true";
import { default as indexrkA1iwkCbFMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/productlists/viewed-products/index.vue?macro=true";
import { default as indexLIG67VPRXiMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/register/index.vue?macro=true";
import { default as success3ZwxIHZlDTMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/register/success.vue?macro=true";
import { default as checkout1sxJtnJgARMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/repairshop/checkout.vue?macro=true";
import { default as indexyYnNQqzpA9Meta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/sales/[productId]/index.vue?macro=true";
import { default as indexpC7ljQNQ25Meta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/shop/[...slug]/index.vue?macro=true";
import { default as indexVS3YtjXijeMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/shop/manufacturer/[...slug]/index.vue?macro=true";
import { default as _91id_93fOpNWosoN3Meta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/shop/product/[...slug]/[id].vue?macro=true";
import { default as index0IZRyEhnzWMeta } from "/azp/_work/1/s/frontend/app/src/pages/minilu/shop/search/[...slug]/index.vue?macro=true";
import { default as overviewyBvVX9N5Q3Meta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/_/banner/overview.vue?macro=true";
import { default as indexyt0hDUgO0fMeta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/[...slug]/index.vue?macro=true";
import { default as thank_45youRFWGnV49WKMeta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/[...slug]/thank-you.vue?macro=true";
import { default as indexQysuDLDkXSMeta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/404/index.vue?macro=true";
import { default as indexEGn6q0HSFRMeta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/account/changePassword/index.vue?macro=true";
import { default as successOTzJ7GItyoMeta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/account/changePassword/success.vue?macro=true";
import { default as index4s5axixpYyMeta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/account/index.vue?macro=true";
import { default as indexjApiETxl95Meta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/account/orders/index.vue?macro=true";
import { default as indexv6qpar8EI7Meta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/account/profile/index.vue?macro=true";
import { default as indexEipuO50YIUMeta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/account/resetPassword/index.vue?macro=true";
import { default as successCHTw6B8O6NMeta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/account/resetPassword/success.vue?macro=true";
import { default as itemswo3KKoLAiXMeta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/account/returns/checkout/items.vue?macro=true";
import { default as reviewski4qJVw2lMeta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/account/returns/checkout/review.vue?macro=true";
import { default as shipping0pX6McgnVRMeta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/account/returns/checkout/shipping.vue?macro=true";
import { default as formKB3nXtRLx5Meta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/account/returns/complaint/form.vue?macro=true";
import { default as index8E3KN58U7yMeta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/account/returns/index.vue?macro=true";
import { default as address7zWM6pviV5Meta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/checkout/address.vue?macro=true";
import { default as basketaIumvWCUrZMeta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/checkout/basket.vue?macro=true";
import { default as loginTOUssHwBy6Meta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/checkout/login.vue?macro=true";
import { default as payment_45shippingErkqjwzaYkMeta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/checkout/payment-shipping.vue?macro=true";
import { default as review73qzcSsMoFMeta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/checkout/review.vue?macro=true";
import { default as theme_45testaT9zCo6LbpMeta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/debug/theme-test.vue?macro=true";
import { default as toolFP2H0K27vJMeta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/debug/tool.vue?macro=true";
import { default as indexYr4O980WU8Meta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/login/index.vue?macro=true";
import { default as successzyK5BKm2CsMeta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/login/success.vue?macro=true";
import { default as _91slug_93RcaVc3uGyeMeta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/preview/banner/[slug].vue?macro=true";
import { default as index8YTjxxK26WMeta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/productlists/auto-delivery/index.vue?macro=true";
import { default as _91id_93srJTp7y5xqMeta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/productlists/favorites/[...slug]/[id].vue?macro=true";
import { default as indexJRVMaleMbeMeta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/productlists/favorites/index.vue?macro=true";
import { default as indexE6bkW1Y28UMeta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/productlists/index.vue?macro=true";
import { default as indexWm1fOw8KY2Meta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/productlists/order-alarm/index.vue?macro=true";
import { default as indexlNvs4nwtNxMeta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/productlists/ordered-products/index.vue?macro=true";
import { default as indextODgCTU6ynMeta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/productlists/viewed-products/index.vue?macro=true";
import { default as indexAfEkZfWVrSMeta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/register/index.vue?macro=true";
import { default as successvF437ZncgKMeta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/register/success.vue?macro=true";
import { default as checkoutmp46gBziVmMeta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/repairshop/checkout.vue?macro=true";
import { default as indexY12xU9Hza0Meta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/sales/[productId]/index.vue?macro=true";
import { default as indexdaPNq8Cks5Meta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/shop/[...slug]/index.vue?macro=true";
import { default as indexQmppgHpVyIMeta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/shop/manufacturer/[...slug]/index.vue?macro=true";
import { default as _91id_93iBQdNyxcBmMeta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/shop/product/[...slug]/[id].vue?macro=true";
import { default as indexuSWOWBNIzFMeta } from "/azp/_work/1/s/frontend/app/src/pages/vdv/shop/search/[...slug]/index.vue?macro=true";
export default [
  {
    name: overviewAkuahBuksYMeta?.name ?? "minilu-_-banner-overview",
    path: overviewAkuahBuksYMeta?.path ?? "/minilu/_/banner/overview",
    meta: overviewAkuahBuksYMeta || {},
    alias: overviewAkuahBuksYMeta?.alias || [],
    redirect: overviewAkuahBuksYMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/_/banner/overview.vue").then(m => m.default || m)
  },
  {
    name: index73MLNudL3hMeta?.name ?? "minilu-slug",
    path: index73MLNudL3hMeta?.path ?? "/minilu/:slug(.*)*",
    meta: index73MLNudL3hMeta || {},
    alias: index73MLNudL3hMeta?.alias || [],
    redirect: index73MLNudL3hMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: thank_45youOo6f4Bd5jOMeta?.name ?? "minilu-slug-thank-you",
    path: thank_45youOo6f4Bd5jOMeta?.path ?? "/minilu/:slug(.*)*/thank-you",
    meta: thank_45youOo6f4Bd5jOMeta || {},
    alias: thank_45youOo6f4Bd5jOMeta?.alias || [],
    redirect: thank_45youOo6f4Bd5jOMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/[...slug]/thank-you.vue").then(m => m.default || m)
  },
  {
    name: index0qf58pPnKvMeta?.name ?? "minilu-404",
    path: index0qf58pPnKvMeta?.path ?? "/minilu/404",
    meta: index0qf58pPnKvMeta || {},
    alias: index0qf58pPnKvMeta?.alias || [],
    redirect: index0qf58pPnKvMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/404/index.vue").then(m => m.default || m)
  },
  {
    name: indexcORn4zXKxKMeta?.name ?? "minilu-account-changePassword",
    path: indexcORn4zXKxKMeta?.path ?? "/minilu/account/changePassword",
    meta: indexcORn4zXKxKMeta || {},
    alias: indexcORn4zXKxKMeta?.alias || [],
    redirect: indexcORn4zXKxKMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/account/changePassword/index.vue").then(m => m.default || m)
  },
  {
    name: successsbK0tyUvvIMeta?.name ?? "minilu-account-changePassword-success",
    path: successsbK0tyUvvIMeta?.path ?? "/minilu/account/changePassword/success",
    meta: successsbK0tyUvvIMeta || {},
    alias: successsbK0tyUvvIMeta?.alias || [],
    redirect: successsbK0tyUvvIMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/account/changePassword/success.vue").then(m => m.default || m)
  },
  {
    name: indexZbSjlZVK4rMeta?.name ?? "minilu-account",
    path: indexZbSjlZVK4rMeta?.path ?? "/minilu/account",
    meta: indexZbSjlZVK4rMeta || {},
    alias: indexZbSjlZVK4rMeta?.alias || [],
    redirect: indexZbSjlZVK4rMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexzP4KpywIfiMeta?.name ?? "minilu-account-orders",
    path: indexzP4KpywIfiMeta?.path ?? "/minilu/account/orders",
    meta: indexzP4KpywIfiMeta || {},
    alias: indexzP4KpywIfiMeta?.alias || [],
    redirect: indexzP4KpywIfiMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexhBLdxZF0PpMeta?.name ?? "minilu-account-profile",
    path: indexhBLdxZF0PpMeta?.path ?? "/minilu/account/profile",
    meta: indexhBLdxZF0PpMeta || {},
    alias: indexhBLdxZF0PpMeta?.alias || [],
    redirect: indexhBLdxZF0PpMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexitBeZ4moR9Meta?.name ?? "minilu-account-resetPassword",
    path: indexitBeZ4moR9Meta?.path ?? "/minilu/account/resetPassword",
    meta: indexitBeZ4moR9Meta || {},
    alias: indexitBeZ4moR9Meta?.alias || [],
    redirect: indexitBeZ4moR9Meta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/account/resetPassword/index.vue").then(m => m.default || m)
  },
  {
    name: successu13ZT6LkdQMeta?.name ?? "minilu-account-resetPassword-success",
    path: successu13ZT6LkdQMeta?.path ?? "/minilu/account/resetPassword/success",
    meta: successu13ZT6LkdQMeta || {},
    alias: successu13ZT6LkdQMeta?.alias || [],
    redirect: successu13ZT6LkdQMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/account/resetPassword/success.vue").then(m => m.default || m)
  },
  {
    name: itemsE1xGricw7sMeta?.name ?? "minilu-account-returns-checkout-items",
    path: itemsE1xGricw7sMeta?.path ?? "/minilu/account/returns/checkout/items",
    meta: itemsE1xGricw7sMeta || {},
    alias: itemsE1xGricw7sMeta?.alias || [],
    redirect: itemsE1xGricw7sMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/account/returns/checkout/items.vue").then(m => m.default || m)
  },
  {
    name: reviewiTrUQdQ96gMeta?.name ?? "minilu-account-returns-checkout-review",
    path: reviewiTrUQdQ96gMeta?.path ?? "/minilu/account/returns/checkout/review",
    meta: reviewiTrUQdQ96gMeta || {},
    alias: reviewiTrUQdQ96gMeta?.alias || [],
    redirect: reviewiTrUQdQ96gMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/account/returns/checkout/review.vue").then(m => m.default || m)
  },
  {
    name: shippingo53mbC5o5iMeta?.name ?? "minilu-account-returns-checkout-shipping",
    path: shippingo53mbC5o5iMeta?.path ?? "/minilu/account/returns/checkout/shipping",
    meta: shippingo53mbC5o5iMeta || {},
    alias: shippingo53mbC5o5iMeta?.alias || [],
    redirect: shippingo53mbC5o5iMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/account/returns/checkout/shipping.vue").then(m => m.default || m)
  },
  {
    name: formse9ltLOKxiMeta?.name ?? "minilu-account-returns-complaint-form",
    path: formse9ltLOKxiMeta?.path ?? "/minilu/account/returns/complaint/form",
    meta: formse9ltLOKxiMeta || {},
    alias: formse9ltLOKxiMeta?.alias || [],
    redirect: formse9ltLOKxiMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/account/returns/complaint/form.vue").then(m => m.default || m)
  },
  {
    name: indexTllS93FZetMeta?.name ?? "minilu-account-returns",
    path: indexTllS93FZetMeta?.path ?? "/minilu/account/returns",
    meta: indexTllS93FZetMeta || {},
    alias: indexTllS93FZetMeta?.alias || [],
    redirect: indexTllS93FZetMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/account/returns/index.vue").then(m => m.default || m)
  },
  {
    name: address1WGt9DT7W8Meta?.name ?? "minilu-checkout-address",
    path: address1WGt9DT7W8Meta?.path ?? "/minilu/checkout/address",
    meta: address1WGt9DT7W8Meta || {},
    alias: address1WGt9DT7W8Meta?.alias || [],
    redirect: address1WGt9DT7W8Meta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/checkout/address.vue").then(m => m.default || m)
  },
  {
    name: basketsWNsuKFVhXMeta?.name ?? "minilu-checkout-basket",
    path: basketsWNsuKFVhXMeta?.path ?? "/minilu/checkout/basket",
    meta: basketsWNsuKFVhXMeta || {},
    alias: basketsWNsuKFVhXMeta?.alias || [],
    redirect: basketsWNsuKFVhXMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/checkout/basket.vue").then(m => m.default || m)
  },
  {
    name: loginRm6TfayuOMMeta?.name ?? "minilu-checkout-login",
    path: loginRm6TfayuOMMeta?.path ?? "/minilu/checkout/login",
    meta: loginRm6TfayuOMMeta || {},
    alias: loginRm6TfayuOMMeta?.alias || [],
    redirect: loginRm6TfayuOMMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/checkout/login.vue").then(m => m.default || m)
  },
  {
    name: payment_45shippingMtj78GueNiMeta?.name ?? "minilu-checkout-payment-shipping",
    path: payment_45shippingMtj78GueNiMeta?.path ?? "/minilu/checkout/payment-shipping",
    meta: payment_45shippingMtj78GueNiMeta || {},
    alias: payment_45shippingMtj78GueNiMeta?.alias || [],
    redirect: payment_45shippingMtj78GueNiMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/checkout/payment-shipping.vue").then(m => m.default || m)
  },
  {
    name: reviewcwdgDP8fqiMeta?.name ?? "minilu-checkout-review",
    path: reviewcwdgDP8fqiMeta?.path ?? "/minilu/checkout/review",
    meta: reviewcwdgDP8fqiMeta || {},
    alias: reviewcwdgDP8fqiMeta?.alias || [],
    redirect: reviewcwdgDP8fqiMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/checkout/review.vue").then(m => m.default || m)
  },
  {
    name: theme_45testnNuKo5hP21Meta?.name ?? "minilu-debug-theme-test",
    path: theme_45testnNuKo5hP21Meta?.path ?? "/minilu/debug/theme-test",
    meta: theme_45testnNuKo5hP21Meta || {},
    alias: theme_45testnNuKo5hP21Meta?.alias || [],
    redirect: theme_45testnNuKo5hP21Meta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/debug/theme-test.vue").then(m => m.default || m)
  },
  {
    name: tool1UO2P42slKMeta?.name ?? "minilu-debug-tool",
    path: tool1UO2P42slKMeta?.path ?? "/minilu/debug/tool",
    meta: tool1UO2P42slKMeta || {},
    alias: tool1UO2P42slKMeta?.alias || [],
    redirect: tool1UO2P42slKMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/debug/tool.vue").then(m => m.default || m)
  },
  {
    name: indexjeOgi9qUiQMeta?.name ?? "minilu-login",
    path: indexjeOgi9qUiQMeta?.path ?? "/minilu/login",
    meta: indexjeOgi9qUiQMeta || {},
    alias: indexjeOgi9qUiQMeta?.alias || [],
    redirect: indexjeOgi9qUiQMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/login/index.vue").then(m => m.default || m)
  },
  {
    name: successUkT9VpSGU0Meta?.name ?? "minilu-login-success",
    path: successUkT9VpSGU0Meta?.path ?? "/minilu/login/success",
    meta: successUkT9VpSGU0Meta || {},
    alias: successUkT9VpSGU0Meta?.alias || [],
    redirect: successUkT9VpSGU0Meta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/login/success.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Oafyttdu0JMeta?.name ?? "minilu-preview-banner-slug",
    path: _91slug_93Oafyttdu0JMeta?.path ?? "/minilu/preview/banner/:slug()",
    meta: _91slug_93Oafyttdu0JMeta || {},
    alias: _91slug_93Oafyttdu0JMeta?.alias || [],
    redirect: _91slug_93Oafyttdu0JMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/preview/banner/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexqFWqcFZlHMMeta?.name ?? "minilu-productlists-auto-delivery",
    path: indexqFWqcFZlHMMeta?.path ?? "/minilu/productlists/auto-delivery",
    meta: indexqFWqcFZlHMMeta || {},
    alias: indexqFWqcFZlHMMeta?.alias || [],
    redirect: indexqFWqcFZlHMMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/productlists/auto-delivery/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Mr2zbZZ430Meta?.name ?? "minilu-productlists-favorites-slug-id",
    path: _91id_93Mr2zbZZ430Meta?.path ?? "/minilu/productlists/favorites/:slug(.*)*/:id()",
    meta: _91id_93Mr2zbZZ430Meta || {},
    alias: _91id_93Mr2zbZZ430Meta?.alias || [],
    redirect: _91id_93Mr2zbZZ430Meta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/productlists/favorites/[...slug]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexYvN5DvXmeHMeta?.name ?? "minilu-productlists-favorites",
    path: indexYvN5DvXmeHMeta?.path ?? "/minilu/productlists/favorites",
    meta: indexYvN5DvXmeHMeta || {},
    alias: indexYvN5DvXmeHMeta?.alias || [],
    redirect: indexYvN5DvXmeHMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/productlists/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: indexwvvPpENwF9Meta?.name ?? "minilu-productlists",
    path: indexwvvPpENwF9Meta?.path ?? "/minilu/productlists",
    meta: indexwvvPpENwF9Meta || {},
    alias: indexwvvPpENwF9Meta?.alias || [],
    redirect: indexwvvPpENwF9Meta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/productlists/index.vue").then(m => m.default || m)
  },
  {
    name: indexhgcdUk12FwMeta?.name ?? "minilu-productlists-order-alarm",
    path: indexhgcdUk12FwMeta?.path ?? "/minilu/productlists/order-alarm",
    meta: indexhgcdUk12FwMeta || {},
    alias: indexhgcdUk12FwMeta?.alias || [],
    redirect: indexhgcdUk12FwMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/productlists/order-alarm/index.vue").then(m => m.default || m)
  },
  {
    name: index9DwGcWA5XBMeta?.name ?? "minilu-productlists-ordered-products",
    path: index9DwGcWA5XBMeta?.path ?? "/minilu/productlists/ordered-products",
    meta: index9DwGcWA5XBMeta || {},
    alias: index9DwGcWA5XBMeta?.alias || [],
    redirect: index9DwGcWA5XBMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/productlists/ordered-products/index.vue").then(m => m.default || m)
  },
  {
    name: indexrkA1iwkCbFMeta?.name ?? "minilu-productlists-viewed-products",
    path: indexrkA1iwkCbFMeta?.path ?? "/minilu/productlists/viewed-products",
    meta: indexrkA1iwkCbFMeta || {},
    alias: indexrkA1iwkCbFMeta?.alias || [],
    redirect: indexrkA1iwkCbFMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/productlists/viewed-products/index.vue").then(m => m.default || m)
  },
  {
    name: indexLIG67VPRXiMeta?.name ?? "minilu-register",
    path: indexLIG67VPRXiMeta?.path ?? "/minilu/register",
    meta: indexLIG67VPRXiMeta || {},
    alias: indexLIG67VPRXiMeta?.alias || [],
    redirect: indexLIG67VPRXiMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/register/index.vue").then(m => m.default || m)
  },
  {
    name: success3ZwxIHZlDTMeta?.name ?? "minilu-register-success",
    path: success3ZwxIHZlDTMeta?.path ?? "/minilu/register/success",
    meta: success3ZwxIHZlDTMeta || {},
    alias: success3ZwxIHZlDTMeta?.alias || [],
    redirect: success3ZwxIHZlDTMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/register/success.vue").then(m => m.default || m)
  },
  {
    name: checkout1sxJtnJgARMeta?.name ?? "minilu-repairshop-checkout",
    path: checkout1sxJtnJgARMeta?.path ?? "/minilu/repairshop/checkout",
    meta: checkout1sxJtnJgARMeta || {},
    alias: checkout1sxJtnJgARMeta?.alias || [],
    redirect: checkout1sxJtnJgARMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/repairshop/checkout.vue").then(m => m.default || m)
  },
  {
    name: indexyYnNQqzpA9Meta?.name ?? "minilu-sales-productId",
    path: indexyYnNQqzpA9Meta?.path ?? "/minilu/sales/:productId()",
    meta: indexyYnNQqzpA9Meta || {},
    alias: indexyYnNQqzpA9Meta?.alias || [],
    redirect: indexyYnNQqzpA9Meta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/sales/[productId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexpC7ljQNQ25Meta?.name ?? "minilu-shop-slug",
    path: indexpC7ljQNQ25Meta?.path ?? "/minilu/shop/:slug(.*)*",
    meta: indexpC7ljQNQ25Meta || {},
    alias: indexpC7ljQNQ25Meta?.alias || [],
    redirect: indexpC7ljQNQ25Meta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/shop/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexVS3YtjXijeMeta?.name ?? "minilu-shop-manufacturer-slug",
    path: indexVS3YtjXijeMeta?.path ?? "/minilu/shop/manufacturer/:slug(.*)*",
    meta: indexVS3YtjXijeMeta || {},
    alias: indexVS3YtjXijeMeta?.alias || [],
    redirect: indexVS3YtjXijeMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/shop/manufacturer/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93fOpNWosoN3Meta?.name ?? "minilu-shop-product-slug-id",
    path: _91id_93fOpNWosoN3Meta?.path ?? "/minilu/shop/product/:slug(.*)*/:id()",
    meta: _91id_93fOpNWosoN3Meta || {},
    alias: _91id_93fOpNWosoN3Meta?.alias || [],
    redirect: _91id_93fOpNWosoN3Meta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/shop/product/[...slug]/[id].vue").then(m => m.default || m)
  },
  {
    name: index0IZRyEhnzWMeta?.name ?? "minilu-shop-search-slug",
    path: index0IZRyEhnzWMeta?.path ?? "/minilu/shop/search/:slug(.*)*",
    meta: index0IZRyEhnzWMeta || {},
    alias: index0IZRyEhnzWMeta?.alias || [],
    redirect: index0IZRyEhnzWMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/minilu/shop/search/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: overviewyBvVX9N5Q3Meta?.name ?? "vdv-_-banner-overview",
    path: overviewyBvVX9N5Q3Meta?.path ?? "/vdv/_/banner/overview",
    meta: overviewyBvVX9N5Q3Meta || {},
    alias: overviewyBvVX9N5Q3Meta?.alias || [],
    redirect: overviewyBvVX9N5Q3Meta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/_/banner/overview.vue").then(m => m.default || m)
  },
  {
    name: indexyt0hDUgO0fMeta?.name ?? "vdv-slug",
    path: indexyt0hDUgO0fMeta?.path ?? "/vdv/:slug(.*)*",
    meta: indexyt0hDUgO0fMeta || {},
    alias: indexyt0hDUgO0fMeta?.alias || [],
    redirect: indexyt0hDUgO0fMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: thank_45youRFWGnV49WKMeta?.name ?? "vdv-slug-thank-you",
    path: thank_45youRFWGnV49WKMeta?.path ?? "/vdv/:slug(.*)*/thank-you",
    meta: thank_45youRFWGnV49WKMeta || {},
    alias: thank_45youRFWGnV49WKMeta?.alias || [],
    redirect: thank_45youRFWGnV49WKMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/[...slug]/thank-you.vue").then(m => m.default || m)
  },
  {
    name: indexQysuDLDkXSMeta?.name ?? "vdv-404",
    path: indexQysuDLDkXSMeta?.path ?? "/vdv/404",
    meta: indexQysuDLDkXSMeta || {},
    alias: indexQysuDLDkXSMeta?.alias || [],
    redirect: indexQysuDLDkXSMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/404/index.vue").then(m => m.default || m)
  },
  {
    name: indexEGn6q0HSFRMeta?.name ?? "vdv-account-changePassword",
    path: indexEGn6q0HSFRMeta?.path ?? "/vdv/account/changePassword",
    meta: indexEGn6q0HSFRMeta || {},
    alias: indexEGn6q0HSFRMeta?.alias || [],
    redirect: indexEGn6q0HSFRMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/account/changePassword/index.vue").then(m => m.default || m)
  },
  {
    name: successOTzJ7GItyoMeta?.name ?? "vdv-account-changePassword-success",
    path: successOTzJ7GItyoMeta?.path ?? "/vdv/account/changePassword/success",
    meta: successOTzJ7GItyoMeta || {},
    alias: successOTzJ7GItyoMeta?.alias || [],
    redirect: successOTzJ7GItyoMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/account/changePassword/success.vue").then(m => m.default || m)
  },
  {
    name: index4s5axixpYyMeta?.name ?? "vdv-account",
    path: index4s5axixpYyMeta?.path ?? "/vdv/account",
    meta: index4s5axixpYyMeta || {},
    alias: index4s5axixpYyMeta?.alias || [],
    redirect: index4s5axixpYyMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexjApiETxl95Meta?.name ?? "vdv-account-orders",
    path: indexjApiETxl95Meta?.path ?? "/vdv/account/orders",
    meta: indexjApiETxl95Meta || {},
    alias: indexjApiETxl95Meta?.alias || [],
    redirect: indexjApiETxl95Meta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexv6qpar8EI7Meta?.name ?? "vdv-account-profile",
    path: indexv6qpar8EI7Meta?.path ?? "/vdv/account/profile",
    meta: indexv6qpar8EI7Meta || {},
    alias: indexv6qpar8EI7Meta?.alias || [],
    redirect: indexv6qpar8EI7Meta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexEipuO50YIUMeta?.name ?? "vdv-account-resetPassword",
    path: indexEipuO50YIUMeta?.path ?? "/vdv/account/resetPassword",
    meta: indexEipuO50YIUMeta || {},
    alias: indexEipuO50YIUMeta?.alias || [],
    redirect: indexEipuO50YIUMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/account/resetPassword/index.vue").then(m => m.default || m)
  },
  {
    name: successCHTw6B8O6NMeta?.name ?? "vdv-account-resetPassword-success",
    path: successCHTw6B8O6NMeta?.path ?? "/vdv/account/resetPassword/success",
    meta: successCHTw6B8O6NMeta || {},
    alias: successCHTw6B8O6NMeta?.alias || [],
    redirect: successCHTw6B8O6NMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/account/resetPassword/success.vue").then(m => m.default || m)
  },
  {
    name: itemswo3KKoLAiXMeta?.name ?? "vdv-account-returns-checkout-items",
    path: itemswo3KKoLAiXMeta?.path ?? "/vdv/account/returns/checkout/items",
    meta: itemswo3KKoLAiXMeta || {},
    alias: itemswo3KKoLAiXMeta?.alias || [],
    redirect: itemswo3KKoLAiXMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/account/returns/checkout/items.vue").then(m => m.default || m)
  },
  {
    name: reviewski4qJVw2lMeta?.name ?? "vdv-account-returns-checkout-review",
    path: reviewski4qJVw2lMeta?.path ?? "/vdv/account/returns/checkout/review",
    meta: reviewski4qJVw2lMeta || {},
    alias: reviewski4qJVw2lMeta?.alias || [],
    redirect: reviewski4qJVw2lMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/account/returns/checkout/review.vue").then(m => m.default || m)
  },
  {
    name: shipping0pX6McgnVRMeta?.name ?? "vdv-account-returns-checkout-shipping",
    path: shipping0pX6McgnVRMeta?.path ?? "/vdv/account/returns/checkout/shipping",
    meta: shipping0pX6McgnVRMeta || {},
    alias: shipping0pX6McgnVRMeta?.alias || [],
    redirect: shipping0pX6McgnVRMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/account/returns/checkout/shipping.vue").then(m => m.default || m)
  },
  {
    name: formKB3nXtRLx5Meta?.name ?? "vdv-account-returns-complaint-form",
    path: formKB3nXtRLx5Meta?.path ?? "/vdv/account/returns/complaint/form",
    meta: formKB3nXtRLx5Meta || {},
    alias: formKB3nXtRLx5Meta?.alias || [],
    redirect: formKB3nXtRLx5Meta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/account/returns/complaint/form.vue").then(m => m.default || m)
  },
  {
    name: index8E3KN58U7yMeta?.name ?? "vdv-account-returns",
    path: index8E3KN58U7yMeta?.path ?? "/vdv/account/returns",
    meta: index8E3KN58U7yMeta || {},
    alias: index8E3KN58U7yMeta?.alias || [],
    redirect: index8E3KN58U7yMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/account/returns/index.vue").then(m => m.default || m)
  },
  {
    name: address7zWM6pviV5Meta?.name ?? "vdv-checkout-address",
    path: address7zWM6pviV5Meta?.path ?? "/vdv/checkout/address",
    meta: address7zWM6pviV5Meta || {},
    alias: address7zWM6pviV5Meta?.alias || [],
    redirect: address7zWM6pviV5Meta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/checkout/address.vue").then(m => m.default || m)
  },
  {
    name: basketaIumvWCUrZMeta?.name ?? "vdv-checkout-basket",
    path: basketaIumvWCUrZMeta?.path ?? "/vdv/checkout/basket",
    meta: basketaIumvWCUrZMeta || {},
    alias: basketaIumvWCUrZMeta?.alias || [],
    redirect: basketaIumvWCUrZMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/checkout/basket.vue").then(m => m.default || m)
  },
  {
    name: loginTOUssHwBy6Meta?.name ?? "vdv-checkout-login",
    path: loginTOUssHwBy6Meta?.path ?? "/vdv/checkout/login",
    meta: loginTOUssHwBy6Meta || {},
    alias: loginTOUssHwBy6Meta?.alias || [],
    redirect: loginTOUssHwBy6Meta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/checkout/login.vue").then(m => m.default || m)
  },
  {
    name: payment_45shippingErkqjwzaYkMeta?.name ?? "vdv-checkout-payment-shipping",
    path: payment_45shippingErkqjwzaYkMeta?.path ?? "/vdv/checkout/payment-shipping",
    meta: payment_45shippingErkqjwzaYkMeta || {},
    alias: payment_45shippingErkqjwzaYkMeta?.alias || [],
    redirect: payment_45shippingErkqjwzaYkMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/checkout/payment-shipping.vue").then(m => m.default || m)
  },
  {
    name: review73qzcSsMoFMeta?.name ?? "vdv-checkout-review",
    path: review73qzcSsMoFMeta?.path ?? "/vdv/checkout/review",
    meta: review73qzcSsMoFMeta || {},
    alias: review73qzcSsMoFMeta?.alias || [],
    redirect: review73qzcSsMoFMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/checkout/review.vue").then(m => m.default || m)
  },
  {
    name: theme_45testaT9zCo6LbpMeta?.name ?? "vdv-debug-theme-test",
    path: theme_45testaT9zCo6LbpMeta?.path ?? "/vdv/debug/theme-test",
    meta: theme_45testaT9zCo6LbpMeta || {},
    alias: theme_45testaT9zCo6LbpMeta?.alias || [],
    redirect: theme_45testaT9zCo6LbpMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/debug/theme-test.vue").then(m => m.default || m)
  },
  {
    name: toolFP2H0K27vJMeta?.name ?? "vdv-debug-tool",
    path: toolFP2H0K27vJMeta?.path ?? "/vdv/debug/tool",
    meta: toolFP2H0K27vJMeta || {},
    alias: toolFP2H0K27vJMeta?.alias || [],
    redirect: toolFP2H0K27vJMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/debug/tool.vue").then(m => m.default || m)
  },
  {
    name: indexYr4O980WU8Meta?.name ?? "vdv-login",
    path: indexYr4O980WU8Meta?.path ?? "/vdv/login",
    meta: indexYr4O980WU8Meta || {},
    alias: indexYr4O980WU8Meta?.alias || [],
    redirect: indexYr4O980WU8Meta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/login/index.vue").then(m => m.default || m)
  },
  {
    name: successzyK5BKm2CsMeta?.name ?? "vdv-login-success",
    path: successzyK5BKm2CsMeta?.path ?? "/vdv/login/success",
    meta: successzyK5BKm2CsMeta || {},
    alias: successzyK5BKm2CsMeta?.alias || [],
    redirect: successzyK5BKm2CsMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/login/success.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RcaVc3uGyeMeta?.name ?? "vdv-preview-banner-slug",
    path: _91slug_93RcaVc3uGyeMeta?.path ?? "/vdv/preview/banner/:slug()",
    meta: _91slug_93RcaVc3uGyeMeta || {},
    alias: _91slug_93RcaVc3uGyeMeta?.alias || [],
    redirect: _91slug_93RcaVc3uGyeMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/preview/banner/[slug].vue").then(m => m.default || m)
  },
  {
    name: index8YTjxxK26WMeta?.name ?? "vdv-productlists-auto-delivery",
    path: index8YTjxxK26WMeta?.path ?? "/vdv/productlists/auto-delivery",
    meta: index8YTjxxK26WMeta || {},
    alias: index8YTjxxK26WMeta?.alias || [],
    redirect: index8YTjxxK26WMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/productlists/auto-delivery/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93srJTp7y5xqMeta?.name ?? "vdv-productlists-favorites-slug-id",
    path: _91id_93srJTp7y5xqMeta?.path ?? "/vdv/productlists/favorites/:slug(.*)*/:id()",
    meta: _91id_93srJTp7y5xqMeta || {},
    alias: _91id_93srJTp7y5xqMeta?.alias || [],
    redirect: _91id_93srJTp7y5xqMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/productlists/favorites/[...slug]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexJRVMaleMbeMeta?.name ?? "vdv-productlists-favorites",
    path: indexJRVMaleMbeMeta?.path ?? "/vdv/productlists/favorites",
    meta: indexJRVMaleMbeMeta || {},
    alias: indexJRVMaleMbeMeta?.alias || [],
    redirect: indexJRVMaleMbeMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/productlists/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: indexE6bkW1Y28UMeta?.name ?? "vdv-productlists",
    path: indexE6bkW1Y28UMeta?.path ?? "/vdv/productlists",
    meta: indexE6bkW1Y28UMeta || {},
    alias: indexE6bkW1Y28UMeta?.alias || [],
    redirect: indexE6bkW1Y28UMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/productlists/index.vue").then(m => m.default || m)
  },
  {
    name: indexWm1fOw8KY2Meta?.name ?? "vdv-productlists-order-alarm",
    path: indexWm1fOw8KY2Meta?.path ?? "/vdv/productlists/order-alarm",
    meta: indexWm1fOw8KY2Meta || {},
    alias: indexWm1fOw8KY2Meta?.alias || [],
    redirect: indexWm1fOw8KY2Meta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/productlists/order-alarm/index.vue").then(m => m.default || m)
  },
  {
    name: indexlNvs4nwtNxMeta?.name ?? "vdv-productlists-ordered-products",
    path: indexlNvs4nwtNxMeta?.path ?? "/vdv/productlists/ordered-products",
    meta: indexlNvs4nwtNxMeta || {},
    alias: indexlNvs4nwtNxMeta?.alias || [],
    redirect: indexlNvs4nwtNxMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/productlists/ordered-products/index.vue").then(m => m.default || m)
  },
  {
    name: indextODgCTU6ynMeta?.name ?? "vdv-productlists-viewed-products",
    path: indextODgCTU6ynMeta?.path ?? "/vdv/productlists/viewed-products",
    meta: indextODgCTU6ynMeta || {},
    alias: indextODgCTU6ynMeta?.alias || [],
    redirect: indextODgCTU6ynMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/productlists/viewed-products/index.vue").then(m => m.default || m)
  },
  {
    name: indexAfEkZfWVrSMeta?.name ?? "vdv-register",
    path: indexAfEkZfWVrSMeta?.path ?? "/vdv/register",
    meta: indexAfEkZfWVrSMeta || {},
    alias: indexAfEkZfWVrSMeta?.alias || [],
    redirect: indexAfEkZfWVrSMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/register/index.vue").then(m => m.default || m)
  },
  {
    name: successvF437ZncgKMeta?.name ?? "vdv-register-success",
    path: successvF437ZncgKMeta?.path ?? "/vdv/register/success",
    meta: successvF437ZncgKMeta || {},
    alias: successvF437ZncgKMeta?.alias || [],
    redirect: successvF437ZncgKMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/register/success.vue").then(m => m.default || m)
  },
  {
    name: checkoutmp46gBziVmMeta?.name ?? "vdv-repairshop-checkout",
    path: checkoutmp46gBziVmMeta?.path ?? "/vdv/repairshop/checkout",
    meta: checkoutmp46gBziVmMeta || {},
    alias: checkoutmp46gBziVmMeta?.alias || [],
    redirect: checkoutmp46gBziVmMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/repairshop/checkout.vue").then(m => m.default || m)
  },
  {
    name: indexY12xU9Hza0Meta?.name ?? "vdv-sales-productId",
    path: indexY12xU9Hza0Meta?.path ?? "/vdv/sales/:productId()",
    meta: indexY12xU9Hza0Meta || {},
    alias: indexY12xU9Hza0Meta?.alias || [],
    redirect: indexY12xU9Hza0Meta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/sales/[productId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexdaPNq8Cks5Meta?.name ?? "vdv-shop-slug",
    path: indexdaPNq8Cks5Meta?.path ?? "/vdv/shop/:slug(.*)*",
    meta: indexdaPNq8Cks5Meta || {},
    alias: indexdaPNq8Cks5Meta?.alias || [],
    redirect: indexdaPNq8Cks5Meta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/shop/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexQmppgHpVyIMeta?.name ?? "vdv-shop-manufacturer-slug",
    path: indexQmppgHpVyIMeta?.path ?? "/vdv/shop/manufacturer/:slug(.*)*",
    meta: indexQmppgHpVyIMeta || {},
    alias: indexQmppgHpVyIMeta?.alias || [],
    redirect: indexQmppgHpVyIMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/shop/manufacturer/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93iBQdNyxcBmMeta?.name ?? "vdv-shop-product-slug-id",
    path: _91id_93iBQdNyxcBmMeta?.path ?? "/vdv/shop/product/:slug(.*)*/:id()",
    meta: _91id_93iBQdNyxcBmMeta || {},
    alias: _91id_93iBQdNyxcBmMeta?.alias || [],
    redirect: _91id_93iBQdNyxcBmMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/shop/product/[...slug]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexuSWOWBNIzFMeta?.name ?? "vdv-shop-search-slug",
    path: indexuSWOWBNIzFMeta?.path ?? "/vdv/shop/search/:slug(.*)*",
    meta: indexuSWOWBNIzFMeta || {},
    alias: indexuSWOWBNIzFMeta?.alias || [],
    redirect: indexuSWOWBNIzFMeta?.redirect,
    component: () => import("/azp/_work/1/s/frontend/app/src/pages/vdv/shop/search/[...slug]/index.vue").then(m => m.default || m)
  }
]